.TerminalCarriersRenderer {
  display: flex;
  flex-direction: row;

  &__cell {
    padding: 10px 14px;
    border-bottom: 1px solid rgb(128, 128, 128, 0.3);
    border-collapse: collapse;
    box-sizing: border-box;
  }

  &__logo {
    display: flex;
    align-items: center;
    gap: 6px;

    &__name {
      font-style: italic;
    }
  }

  &__row {
    color: $color-text-neutral-weakest;
    font-size: 14px;
    font-style: italic;
  }
}

.CarrierActions {
  &__save {
    mc-button::part(button) {
      color: #328529;
    }
    mc-button::part(icon) {
      fill: #328529;
    }
  }

  &__cancel {
    mc-button::part(button) {
      color: #B80012;
    }
    mc-button::part(icon) {
      fill: #B80012;
    }
  }
}
